// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
@media (min-width: 2600px) {
    .outerContainer {
        width: 60%;
    }
}

/* 
@media (max-width: 2600px) {
    .outerContainer {
        width: 65%;
    }
}


@media (max-width: 2400px) {
    .outerContainer {
        width: 75%;
    }
}



@media (max-width: 2200px) {
    .outerContainer {
        width: 80% ;
    }
}

@media (max-width: 2000px) {
    .outerContainer {
        width: 100% ;
    }
} 
 */
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/Home.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAkCE","sourcesContent":["/* \n@media (min-width: 2600px) {\n    .outerContainer {\n        width: 60%;\n    }\n}\n\n/* \n@media (max-width: 2600px) {\n    .outerContainer {\n        width: 65%;\n    }\n}\n\n\n@media (max-width: 2400px) {\n    .outerContainer {\n        width: 75%;\n    }\n}\n\n\n\n@media (max-width: 2200px) {\n    .outerContainer {\n        width: 80% ;\n    }\n}\n\n@media (max-width: 2000px) {\n    .outerContainer {\n        width: 100% ;\n    }\n} \n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
