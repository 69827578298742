// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills{
    min-height: 25vh;
}

@media (min-width: 2600px) {
    .col-xl-2.col-lg-3{
        font-size:25px;
        
    }
  }
@media (max-width: 2600px) {
    .col-xl-2.col-lg-3{
        width:17%;
        font-size:25px;
    }
  }
  @media (max-width: 1900px) {
    .col-xl-2.col-lg-3{
        width:30%
    }
  }

  @media (min-width: 1200px) {
    .col-xl-6.col-lg-10{
        width:65%;
    }
  }

  @media (max-width: 660px) {
    .col-xl-2.col-lg-3{
        width:40%;
    }
  }


  @media (max-width: 850px) {
    .col-xl-2.col-lg-3{
       font-size: 23px;
    }
  }
  @media (min-width: 490px) {

    img{
        width:25px;
        height:25px;
    }
  }
  @media (max-width: 490px) {
    .col-xl-2.col-lg-3{
        width:45%;
        font-size: 20px;
    }
    img{
        width:20px;
        height:20px;
    }
  }
  @media (max-width: 390px) {
    .col-xl-2.col-lg-3{
        width:45%;
        font-size: 15px;
    }
  }


  @media (max-width: 340px) {
    .col-xl-2.col-lg-3{
        width:40%;
        font-size: 12px;
    }
  }

  @media (max-width: 280px) {
    .col-xl-2.col-lg-3{
        width:80%;
        font-size: 12px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Skill/Skills.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,cAAc;;IAElB;EACF;AACF;IACI;QACI,SAAS;QACT,cAAc;IAClB;EACF;EACA;IACE;QACI;IACJ;EACF;;EAEA;IACE;QACI,SAAS;IACb;EACF;;EAEA;IACE;QACI,SAAS;IACb;EACF;;;EAGA;IACE;OACG,eAAe;IAClB;EACF;EACA;;IAEE;QACI,UAAU;QACV,WAAW;IACf;EACF;EACA;IACE;QACI,SAAS;QACT,eAAe;IACnB;IACA;QACI,UAAU;QACV,WAAW;IACf;EACF;EACA;IACE;QACI,SAAS;QACT,eAAe;IACnB;EACF;;;EAGA;IACE;QACI,SAAS;QACT,eAAe;IACnB;EACF;;EAEA;IACE;QACI,SAAS;QACT,eAAe;IACnB;EACF","sourcesContent":[".skills{\n    min-height: 25vh;\n}\n\n@media (min-width: 2600px) {\n    .col-xl-2.col-lg-3{\n        font-size:25px;\n        \n    }\n  }\n@media (max-width: 2600px) {\n    .col-xl-2.col-lg-3{\n        width:17%;\n        font-size:25px;\n    }\n  }\n  @media (max-width: 1900px) {\n    .col-xl-2.col-lg-3{\n        width:30%\n    }\n  }\n\n  @media (min-width: 1200px) {\n    .col-xl-6.col-lg-10{\n        width:65%;\n    }\n  }\n\n  @media (max-width: 660px) {\n    .col-xl-2.col-lg-3{\n        width:40%;\n    }\n  }\n\n\n  @media (max-width: 850px) {\n    .col-xl-2.col-lg-3{\n       font-size: 23px;\n    }\n  }\n  @media (min-width: 490px) {\n\n    img{\n        width:25px;\n        height:25px;\n    }\n  }\n  @media (max-width: 490px) {\n    .col-xl-2.col-lg-3{\n        width:45%;\n        font-size: 20px;\n    }\n    img{\n        width:20px;\n        height:20px;\n    }\n  }\n  @media (max-width: 390px) {\n    .col-xl-2.col-lg-3{\n        width:45%;\n        font-size: 15px;\n    }\n  }\n\n\n  @media (max-width: 340px) {\n    .col-xl-2.col-lg-3{\n        width:40%;\n        font-size: 12px;\n    }\n  }\n\n  @media (max-width: 280px) {\n    .col-xl-2.col-lg-3{\n        width:80%;\n        font-size: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
