// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron{
    min-height: 100vh;
}

.switch {
    width: 50px;
    height: 100px;
    background-color: rgba(110, 102, 102, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    position: absolute;
    bottom:20px;
  }
  
  .switch[data-isOn="true"] {
    justify-content: flex-end;
  }
  
  .handle {
    width: 40px;
    height: 40px;
    background-color: rgb(78, 74, 74);
    border-radius: 40px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,0CAA0C;IAC1C,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,mBAAmB;EACrB","sourcesContent":[".jumbotron{\n    min-height: 100vh;\n}\n\n.switch {\n    width: 50px;\n    height: 100px;\n    background-color: rgba(110, 102, 102, 0.4);\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    border-radius: 20px;\n    padding: 5px;\n    cursor: pointer;\n    position: absolute;\n    bottom:20px;\n  }\n  \n  .switch[data-isOn=\"true\"] {\n    justify-content: flex-end;\n  }\n  \n  .handle {\n    width: 40px;\n    height: 40px;\n    background-color: rgb(78, 74, 74);\n    border-radius: 40px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
