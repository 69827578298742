// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.projectButton {
    padding:7px 10px;
    width: 100px;
    background: linear-gradient(to left, #ebebeb 50%, #bf7e04 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    cursor: pointer;
    transition: all ease .3s;
    border:1px solid #272020;
    border-radius: 10px; 
  }
  
  button.projectButton:hover {
    background-position: left bottom;
    color: white;
  }


/* @media (min-width: 1600px) {
  .projectContainer {
      min-height: 400px;
  }
}

@media (min-width: 1900px) {
  .projectContainer {
      min-height: 450px;
  }
}

@media (min-width: 2200px) {
  .projectContainer {
      min-height: 500px;
  }
} */`, "",{"version":3,"sources":["webpack://./src/components/Project/Projects.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,8DAA8D;IAC9D,0BAA0B;IAC1B,iCAAiC;IACjC,eAAe;IACf,wBAAwB;IACxB,wBAAwB;IACxB,mBAAmB;EACrB;;EAEA;IACE,gCAAgC;IAChC,YAAY;EACd;;;AAGF;;;;;;;;;;;;;;;;GAgBG","sourcesContent":["button.projectButton {\n    padding:7px 10px;\n    width: 100px;\n    background: linear-gradient(to left, #ebebeb 50%, #bf7e04 50%);\n    background-size: 200% 100%;\n    background-position: right bottom;\n    cursor: pointer;\n    transition: all ease .3s;\n    border:1px solid #272020;\n    border-radius: 10px; \n  }\n  \n  button.projectButton:hover {\n    background-position: left bottom;\n    color: white;\n  }\n\n\n/* @media (min-width: 1600px) {\n  .projectContainer {\n      min-height: 400px;\n  }\n}\n\n@media (min-width: 1900px) {\n  .projectContainer {\n      min-height: 450px;\n  }\n}\n\n@media (min-width: 2200px) {\n  .projectContainer {\n      min-height: 500px;\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
