// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
    margin-top: 100px;
    min-height: 100vh;
}

`, "",{"version":3,"sources":["webpack://./src/pages/RoulettePage/Roulette.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".container{\n    margin-top: 100px;\n    min-height: 100vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
